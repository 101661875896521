import useSWRMutation from 'swr/mutation';

import { useGlobalConfig } from '../../../hooks/configContext';
import UtmLocalStorage from '../../../lib/analytics/queryParams/UtmLocalStorage';
import {
  Cloud$BusinessUnitConfig,
  Cloud$Interest,
  Cloud$SuggestedAddress,
} from '../../../types/cloudApi';
import { Cloud$MainHardwareType } from '../../../types/cloudEnums';
import { handleResponseErrors } from './ErrorHandling/handleResponseErrors';

export type CreateInterestArgs = {
  validatedAddress: Cloud$SuggestedAddress;
  monthlyEnergyBill?: number;
  loanSimulationId?: string;
  experimentVersion?: string;
  locale: string;
  mainHardwareType?: Cloud$MainHardwareType;
};

type CreateInterestUTMType = {
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  google_ads_click_id?: string;
};

type CreateInterestRequest = {
  address: string | undefined;
  address_string: string;
  business_unit: string;
  country: string;
  experiment_version?: string;
  utm_data: CreateInterestUTMType;
  utm_data_initial: CreateInterestUTMType;
  monthly_energy_bill?: number;
  external_loan_simulation_id?: string;
  preferred_locale: string;
  main_hardware_type?: Cloud$MainHardwareType;
};

const sendRequest = async (
  url: string,
  { arg }: { arg: CreateInterestRequest },
) => {
  const response = await fetch(url, {
    method: 'post',
    body: JSON.stringify(arg),
    headers: { 'content-type': 'application/json' },
  });

  handleResponseErrors(response);

  const interest = await response.json();
  if (!interest.id) {
    throw new Error('Unexpected response from Cloud');
  }

  return interest;
};

function resolveBUSlug(config: Cloud$BusinessUnitConfig) {
  if (
    config.matchedPartner &&
    config.matchedPartner.type === 'RESELLER_PARTNER'
  ) {
    return config.matchedPartner.config.slug;
  }

  return config.slug;
}

export default function useCreateInterest() {
  const config = useGlobalConfig();

  const { trigger, isMutating } = useSWRMutation(
    `${process.env.NEXT_PUBLIC_OTOVOAPI_URL}/web/interests/`,
    sendRequest,
  );

  async function createInterest({
    validatedAddress,
    monthlyEnergyBill,
    loanSimulationId,
    experimentVersion,
    locale,
    mainHardwareType,
  }: CreateInterestArgs): Promise<Cloud$Interest> {
    return trigger({
      address: validatedAddress.id || undefined,
      address_string: validatedAddress.text,
      business_unit: resolveBUSlug(config.BU_CONFIG),
      country: config.BU_CONFIG.market,
      utm_data: UtmLocalStorage.retrieve(),
      utm_data_initial: UtmLocalStorage.retrieve_initial(),
      monthly_energy_bill: monthlyEnergyBill || undefined,
      external_loan_simulation_id: loanSimulationId || undefined,
      experiment_version: experimentVersion || undefined,
      preferred_locale: locale,
      ...(mainHardwareType && { main_hardware_type: mainHardwareType }),
    });
  }

  return {
    createInterest,
    isCreatingInterest: isMutating,
  };
}
