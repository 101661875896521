import { useCallback, useEffect, useState } from 'react';

// Use for conditional render on mobile/desktop
const useMobileVersion = (queryOrPixel: number | string): boolean => {
  const mediaQuery =
    typeof queryOrPixel === 'number'
      ? `(max-width: ${queryOrPixel}px)`
      : queryOrPixel;

  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback(
    (e: MediaQueryListEvent) => setTargetReached(e.matches),
    [],
  );

  useEffect(() => {
    if (typeof window.matchMedia === 'function') {
      const media = window.matchMedia(`${mediaQuery}`);
      media.onchange = updateTarget;

      // Check on mount (callback is not called until a change occurs)
      if (media.matches) {
        setTargetReached(true);
      }

      return () => {
        media.onchange = null;
      };
    }
  }, [updateTarget, mediaQuery]);

  return targetReached;
};

export default useMobileVersion;
